import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo";

function ThankYou() {
  return (
    <Layout>
      <SEO title={"My inspiration"}></SEO>
      <h1>Inspiration</h1>
      <p>This is not quite an about page... right? I just want to say, I'm super passionate about crafting, the feedback I get from friends, family and peers is what motivates me. One of my goals in life is to create, to craft and with that, I want to bring happiness.</p>
      <p>So I was thinking. There are so so many of you out there, that get my motivation going and my creative mindset flowing, and I just want to have a space to show that appreciation.</p>
      <p>Therefore, the following list is dedicated (and linked) to some of the people I admire:</p>
      <ul>
        <li>The embroidery inspiration, <a href="https://stitchfloral.blogspot.com" target={'_blank'} rel={"noopener noreferrer"}>Amina</a></li>
        <li>The always positive, <a href="https://www.instagram.com/drdrea_8428" target={'_blank'} rel={"noopener noreferrer"}>DrDrea</a></li>
        <li>The super nice crochet streamer, <a href="https://www.twitch.tv/falsebubbles" target={'_blank'} rel={"noopener noreferrer"}>Falsebubbles</a></li>
        <li>The not swedish, not little, not bear, <a href="https://www.lillabjorncrochet.com" target={'_blank'} rel={"noopener noreferrer"}>LillaBjörn</a></li>
        <li>The maker mom, <a href="https://www.twitch.tv/ninepointfive" target={'_blank'} rel={"noopener noreferrer"}>NinePointFive</a></li>
      </ul>
      <p>There probably much more. I'll try to keep this list updated and all, and also hope that you will find inspiration with these guys.</p>
      <p>I also keep and try to update a pinterest set - a bunch of boards in a semi-organized manner, that I sometimes forget about... sometimes only! <a href="https://www.pinterest.com/madebyrekas/" target={'_blank'}>Find it here!</a></p>
    </Layout>
  )
}

export default ThankYou